<template>
  <div class="user">
    <div class="content-box">
      <div class="conter-router">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.user {
  background: url("../../assets/user/banner.png") no-repeat;
  background-color: #f5f5f5;
  background-position: center top;
  width: 100%;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    .conter-router {
      padding: 30px 0;
      width: 100%;
    }
  }
}
</style>
